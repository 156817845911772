@tailwind base;
@tailwind components;
@tailwind utilities;

.page-home main.container {
  margin: 0;
  max-width: 100%;
}

main.container {
  margin-top: 0;
}

/* Header */
header {
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.8);
}
.header-logo {
  height: 30px;
}

.page-home .header-image {
  background: url(../public/img/header-bg.jpg) 50% 0 no-repeat;
  background-size: cover;
  padding: 10% 2rem 25%;
}

/* Sidebar */
.sidebar-menu {
  width: 300px;
}
.sidebar-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
}
.sidebar-scrollable-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px 24px 170px;
}
.shared-libraries-title {
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #777;
}
.sidebar-footer {
  padding: 16px;
  width: 300px;
  position: fixed;
  left: 0;
  bottom: 0;
}

/* Item Image Fullscreen */
.image-fullscreen img {
  max-width: 90%;
  max-height: 90vh;
  border-radius: 8px;
}

/* Search */
.clear-search-btn {
  right: 3rem;
}

/* Library Share Button */
.share-button span.share-number {
  margin-right: -7px;
}

/* Scrolling */
.scroll {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.scroll::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.scroll .flex {
  display: inline-flex;
}

.scroll .flex > * {
  flex: 0 0 auto;
}